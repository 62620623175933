:root {
  --color-black: #000;
  --color-white: #fff;

  --color-gray-50: #f9fafb;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #edf2f7; /* tailwind uses #e5e7eb */
  --color-gray-300: #e2e8f0; /* tailwind uses #d1d5db */
  --color-gray-400: #cbd5e0; /* tailwind uses #9ca3af */
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;

  --color-red-50: #fef2f2;
  --color-red-100: #fee2e2;
  --color-red-200: #fecaca;
  --color-red-300: #fca5a5;
  --color-red-400: #f87171;
  --color-red-500: #f56565; /* tailwind uses #ef4444 */
  --color-red-600: #dc2626;
  --color-red-700: #b91c1c;
  --color-red-800: #991b1b;
  --color-red-900: #7f1d1d;

  --color-blue-50: #eff6ff;
  --color-blue-100: #dbeafe;
  --color-blue-200: #bfdbfe;
  --color-blue-300: #93c5fd;
  --color-blue-400: #60a5fa;
  --color-blue-500: #4299e1; /* tailwind uses #3b82f6 */
  --color-blue-600: #2563eb;
  --color-blue-700: #2b6cb0; /* tailwind uses #1d4ed8 */
  --color-blue-800: #1e40af;
  --color-blue-900: #1e3a8a;

  --color-allports: #216679;
  --color-bunting: #243352;
  --color-darkStone: #cebda4;
  --color-charcoal: #484848;
  --color-chicago: #5f6258;
  --color-findingNemo: #1f41f7;
  --color-goldenRod: #ffca58;
  --color-grandis: #ffd16c;
  --color-grandisLight: #ffdb8c;
  --color-navy: #155263;
  --color-siam: #585a51;
  --color-stone: #e3d9ca;
  --color-dustyOrange: #ff6f31;
  --color-blackLite: #252525;
  --color-red: #ff0000;
  --color-chathams: #265161;
  --color-wafer: #fff4dd;
  --color-silver: #c1c1c1;
  --color-silverLight: #cacaca;
  --color-silverLighter: #d8d8d8;
  --color-nileBlue: #183d73;
  --color-nileBlueLight: #5d779d;
  --color-whatsappGreen: #25d366;
  --color-whatsappGreenLight: #67e095;
  --color-whatsappGreenDark: #036327;
  --color-facebook: #3b5998;
  --color-facebookLight: #768bb7;
  --color-greyCold: #f7f8fa;
  --color-calypso: #276a7d;
  --color-grayMiddle: #cacaca;
  --color-lightGreen: #46e4a7;
  --color-lightRed: #f06666;
  --color-lightBlue: #5397c5;
  --color-lightGrey: #e1e1e1;
  --color-darkOrange: #e7711b;
  --color-legacyGrey: #979797;
  --color-greyGold: #99978a;
  --color-qunoGrey: #757575;
  --color-qunoRed: #e96264;
  --color-yellowDark: #f2b124;
  --color-sandBlue: #a3c1c9;
  --color-sandBlueLighter: #a5cad5;
  --color-bluishGrey: #eef2f6;
  --color-bluishGreyDarker: #edf2f6;
  --color-bluishGreyDarkest: #d8e0e9;
  --color-insBg: #f4f4f4;
  --color-doctorBg: #e8edf0;
  --color-blueTurquoise: #e8eeef;
  --color-tanLightest: #f7f5f2;
  --color-tan: #c7beb3;
  --color-darkGrey: #212121;
  --color-greenVogue: #18414d;
  --color-darkTurquoise: #136067;
  --color-greyDarkest: #101010;
  --color-dustyChimney: #4b4b4b;

  --color-primary: var(--color-bunting);
  --color-secondary: var(--color-goldenRod);
  --color-secondary-light: #fff8ea;
  --color-secondary-lighter: #fffaee;
  --color-secondary-medium: #fff2d5;
  --color-secondary-bright: #fffcf7;
  --color-secondary-dark: #fff4de;
  --color-secondary-darker: #ffeabc;
  --color-tertiary: var(--color-chicago);
  --color-tertiary-grey: #c4c4c4;
  --color-modal: rgba(0, 0, 0, 0.55);
  --color-infobox: rgba(227, 217, 202, 0.5);
  --color-infobox-light: rgba(255, 244, 222, 0.5);
  --color-infobox-light-full: rgb(255, 244, 222);
  --color-orange-alt: #f86b2f;
  --color-disabled-text: #adadad;
  --color-disabled-bg: #f8f8f8;
  --color-error: var(--color-red);

  --textColor-primary: var(--color-siam);
  --textColor-secondary: var(--color-bunting);
  --textColor-tertiary: var(--color-goldenRod);
  --textColor-tertiary-grey: #bec0bb;
  --textColor-chicago: var(--color-chicago);
  --textColor-dustyChimney: var(--color-dustyChimney);
  --textColor-findingNemo: var(--color-findingNemo);
  --textColor-blackLite: var(--color-blackLite);
  --textColor-error: var(--color-red);
  --textColor-grayMiddle: var(--color-grayMiddle);
  --textColor-darkOrange: var(--color-darkOrange);

  --spacing-0-5: 0.125rem; /* 2px */
  --spacing-1: 0.25rem; /* 4px */
  --spacing-1-5: 0.375rem; /* 6px */
  --spacing-2: 0.5rem; /* 8px */
  --spacing-2-5: 0.625rem; /* 10px */
  --spacing-3: 0.75rem; /* 12px */
  --spacing-3-5: 0.875rem; /* 14px */
  --spacing-4: 1rem; /* 16px */
  --spacing-5: 1.25rem; /* 20px */
  --spacing-6: 1.5rem; /* 24px */
  --spacing-7: 1.75rem; /* 28px */
  --spacing-8: 2rem; /* 32px */
  --spacing-9: 2.25rem; /* 36px */
  --spacing-10: 2.5rem; /* 40px */
  --spacing-11: 2.75rem; /* 44px */
  --spacing-12: 3rem; /* 48px */
  --spacing-14: 3.5rem; /* 56px */
  --spacing-16: 4rem; /* 64px */
  --spacing-20: 5rem; /* 80px */
  --spacing-24: 6rem; /* 96px */
  --spacing-25: 6.25rem; /* 100px */
  --spacing-28: 7rem; /* 112px */
  --spacing-30: 7.5rem; /* 112px */
  --spacing-32: 8rem; /* 128px */
  --spacing-36: 9rem; /* 144px */
  --spacing-40: 10rem; /* 160px */
  --spacing-44: 11rem; /* 176px */
  --spacing-48: 12rem; /* 192px */
  --spacing-52: 13rem; /* 208px */
  --spacing-56: 14rem; /* 224px */
  --spacing-60: 15rem; /* 240px */
  --spacing-64: 16rem; /* 256px */
  --spacing-72: 18rem; /* 288px */
  --spacing-80: 20rem; /* 320px */
  --spacing-82: 20.5rem; /* 328px */
  --spacing-96: 24rem; /* 384px */

  --fontFamily-netto: NettoWeb, Helvetica, Arial, sans-serif;
  --fontFamily-adelle: Adelle Sans, Arial, Helvetica, sans-serif;

  --text-xs-size: 0.75rem; /* 12px */
  --text-xs-line-height: var(--line-height-4);
  --text-sm-size: 0.875rem; /* 14px */
  --text-sm-line-height: var(--line-height-5);
  --text-base-size: 1rem; /* 16px */
  --text-base-line-height: var(--line-height-6);
  --text-lg-size: 1.125rem; /* 18px */
  --text-lg-line-height: var(--line-height-7);
  --text-xl-size: 1.25rem; /* 20px */
  --text-xl-line-height: var(--line-height-7);
  --text-2xl-size: 1.5rem; /* 24px */
  --text-2xl-line-height: var(--line-height-8);
  --text-3xl-size: 1.875rem; /* 30px */
  --text-3xl-line-height: var(--line-height-9);
  --text-4xl-size: 2.25rem; /* 36px */
  --text-4xl-line-height: var(--line-height-10);
  --text-5xl-size: 3rem; /* 48px */
  --text-5xl-line-height: var(--line-height-none);
  --text-6xl-size: 4rem; /* 64px, tailwind uses 3.75rem */
  --text-6xl-line-height: var(--line-height-none);
  --text-7xl-size: 4.5rem; /* 72px */
  --text-7xl-line-height: var(--line-height-none);
  --text-8xl-size: 6rem; /* 96px */
  --text-8xl-line-height: var(--line-height-none);
  --text-9xl-size: 8rem; /* 128px */
  --text-9xl-line-height: var(--line-height-none);

  --line-height-3: 0.75rem; /* 12px */
  --line-height-4: 1rem; /* 16px */
  --line-height-5: 1.25rem; /* 20px */
  --line-height-6: 1.5rem; /* 24px */
  --line-height-7: 1.75rem; /* 28px */
  --line-height-8: 2rem; /* 32px */
  --line-height-9: 2.25rem; /* 36px */
  --line-height-10: 2.5rem; /* 40px */
  --line-height-none: 1;
  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 2;
}
