@font-face {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Adelle Sans'),
    url('/v2/fonts/adelle-sans-regular/adelle-sans-regular.subset.woff2')
      format('woff2'),
    url('/v2/fonts/adelle-sans-regular/adelle-sans-regular.subset.woff')
      format('woff'),
    url('/v2/fonts/adelle-sans-regular/adelle-sans-regular.subset.ttf')
      format('truetype'),
    url('/v2/fonts/adelle-sans-regular/adelle-sans-regular.eot')
      format('embedded-opentype');
  unicode-range: U+0020-007E, U+00A0-00FF, U+20A0-20CF;
}

@font-face {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Adelle Sans'),
    url('/v2/fonts/adelle-sans-bold/adelle-sans-bold.subset.woff2') format('woff2'),
    url('/v2/fonts/adelle-sans-bold/adelle-sans-bold.subset.woff') format('woff'),
    url('/v2/fonts/adelle-sans-bold/adelle-sans-bold.subset.ttf')
      format('truetype'),
    url('/v2/fonts/adelle-sans-bold/adelle-sans-bold.eot')
      format('embedded-opentype');
  unicode-range: U+0020-007E, U+00A0-00FF, U+20A0-20CF;
}

@font-face {
  font-family: 'NettoWeb';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('NettoWeb'),
    url('/v2/fonts/nettoweb-regular/nettoweb-regular.subset.woff2') format('woff2'),
    url('/v2/fonts/nettoweb-regular/nettoweb-regular.subset.woff') format('woff'),
    url('/v2/fonts/nettoweb-regular/nettoweb-regular.subset.ttf')
      format('truetype'),
    url('/v2/fonts/nettoweb-regular/nettoweb-regular.eot')
      format('embedded-opentype');
  unicode-range: U+0020-007E, U+00A0-00FF, U+20A0-20CF;
}

@font-face {
  font-family: 'NettoWeb';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: local('NettoWeb'),
    url('/v2/fonts/nettoweb-black/nettoweb-black.subset.woff2') format('woff2'),
    url('/v2/fonts/nettoweb-black/nettoweb-black.subset.woff') format('woff'),
    url('/v2/fonts/nettoweb-black/nettoweb-black.subset.ttf') format('truetype'),
    url('/v2/fonts/nettoweb-black/nettoweb-black.eot') format('embedded-opentype');
  unicode-range: U+0020-007E, U+00A0-00FF, U+20A0-20CF;
}
