@import 'styles/variables.scss';

.carousel-filter .tr {
  &-light,
  &-dark {
    &__control {
      @media (min-width: $screens-md) {
        padding-left: 40px;
      }
    }
    &__indicator-separator {
      visibility: hidden;
    }
    &__menu {
      border-radius: 0 0 0 8px;
      padding: 8px;
    }
    &__option {
      background: #fff;
      border-radius: 4px;
      color: #484848;
      &:hover {
        background: #ffca58;
        cursor: pointer;
      }
    }
  }

  &-light {
    &__control {
      background-color: var(--color-bluishGrey);
      border-color: var(--color-bluishGrey);
    }
    &__dropdown-indicator {
      svg {
        fill: #155263;
      }
    }

    &__single-value {
      color: #155263;
    }
  }

  &-dark {
    &__control {
      background-color: #212121;
      border-color: #3c3c3c;
    }
    &__dropdown-indicator {
      svg {
        fill: #909090;
      }
    }
    &__single-value {
      color: #fff;
    }
  }
}
